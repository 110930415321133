<template>
  <div>
    <app-header />

    <loading v-if="is_loading" />

    <div v-if="!is_loading">
      <div class="container" v-if="invalid_token">
        <div class="alert alert-warning">
          <h4>Failed</h4>
          <p>The token is invalid!</p>
        </div>
      </div>
      <section
        class="image-cover hero-banner py-5"
        style="background: #eff6ff url(/assets/img/banner-2.jpg) no-repeat"
        v-if="!invalid_token"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-5">
              <div class="card shadow">
                <div class="card-body">
                  <h4>Welcome {{ user.name }},</h4>
                  <h6 class="text-muted">Set up a new password.</h6>
                  <hr />
                  <form @submit.prevent="resetPassword">
                    <div class="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        placeholder="********"
                        required
                        v-model="user.password"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label>Confirm New Password</label>
                      <input
                        type="password"
                        placeholder="********"
                        v-model="user.password_confirmation"
                        required
                        class="form-control"
                      />
                    </div>
                    <div class="form-group text-center">
                      <button class="btn btn-primary">
                        <i class="lni lni-save me-2"></i>Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      is_loading: true,
      invalid_token: false,
      user: {},
    };
  },

  methods: {
    resetPassword() {
      this.is_loading = true
      let data = {
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        token: this.$route.params.token
      }

      this.$axios.post('/api/v1/recover-account-password', data).then(response => {
        this.$auth.setToken(response.data.access_token);

        window.location.href = this.$router.resolve({ name: 'my-account.home' }).href
      })
    },

    fetch() {
      this.is_loading = true;
      this.invalid_token = false;

      let data = {
        token: this.$route.params.token,
      };

      this.$axios
        .post("/api/v1/recover-account", data)
        .then((response) => {
          this.user = response.data.user;
          this.is_loading = false;
          this.invalid_token = false;
        })
        .catch(() => {
          this.is_loading = false;
          this.invalid_token = true;
        });
    },
  },
};
</script>
